import React, { useContext, useState, useEffect } from "react"
import { Outlet, NavLink, useResolvedPath, useMatch, Link, useLocation, useNavigate } from "react-router-dom"
import logoImg from "../assets/images/white-logo.png"
import Dialog from "../components/Dialog"
import { AuthContext } from "../libs/contexts/AuthContext"
import { SnackbarContext } from "../libs/contexts/SnackbarContext"
import api from "../libs/apis/api"
import { handleApiError } from "../libs/apis/handleApiError"
import useLocalStorage from "../utils/useLocalStorage"
import localforage from "localforage"
import { onMessageListener, requestPermission } from "../libs/services/firebase"
import { useIdleTimer } from "react-idle-timer"

const Layout = () => {
  let navigate = useNavigate()
  const location = useLocation()
  const { auth, handleLogout, lang, makeModel, setMakeModel, handleProfileUpdate } = useContext(AuthContext)
  const { openSnackbar } = useContext(SnackbarContext)
  const { getAppItem, setAppItem } = useLocalStorage()
  const [pageReload, setPageReload] = useState(0)
  const [counter, setCounter] = useState({ livefeed: 0, quotes: 0, requests: 0 })
  const [quotes, setQuotes] = useState(null)
  const [requests, setRequests] = useState(null)
  const [validLicense, setValidLicense] = useState(true)
  const [logoutDialog, setLogoutDialog] = useState({ open: false, message: "", handleConfirm: handleLogout })
  const [validSubscription, setValidSubscription] = useState(true)
  const [notificationListener, setNotificationListener] = useState(null)
  const [menuOpen, setMenuOpen] = useState(false)
  let profiletype = JSON.parse(localStorage.getItem("data"))?.profiletype

  // const { getRemainingTime } = useIdleTimer({
  //   timeout: 1000 * 60 * 1,
  //   onActive: () => {
  //     // navigate(0)
  //     setPageReload((prev) => prev + 1)
  //   },
  // })

  const fetchProfile = async (values) => {
    try {
      const { data } = await api.get(`v1/salvage`)
      handleProfileUpdate(data)
    } catch (error) {
      handleApiError(error, openSnackbar)
    }
  }

  const fetchMakeModel = async (values) => {
    try {
      const { data } = await api.get(`make-models`)
      localforage
        .setItem("_makemodel", data)
        .then(function (value) {})
        .catch(function (err) {
          console.log(err)
        })
    } catch (error) {
      handleApiError(error, openSnackbar)
    }
  }

  // Fetching 30 day license expiry
  const fetchValidLicense = async (values) => {
    try {
      const { data } = await api.get(`v1/auth/signup/checkLicense`)
      setValidLicense(data.valid)
      // checkValidity()
      return data.valid
    } catch (error) {
      handleApiError(error, openSnackbar)
    }
  }

  // Checking 30 day license expiry and navigating to Profile
  const checkValidity = () => {
    if (!validLicense) {
      setQuotes([])
      setRequests([])
      navigate("/settings/profile")
      throw {
        message: (
          <>
            <b>Please update your license</b>
          </>
        ),
      }
    }
  }

  const fetchLivefeed = async (p) => {
    try {
      let lf = {}
      // await checkValidity()
      if (p) {
        lf = await api.get(
          `requests/livefeedListV1?size=${p.size}&page=${p.page}`
        );
      } else {
        lf = await api.get(`requests/livefeedListV1?size=8&page=0`)
      }
      livefeedUnreadCounter()
      return lf
    } catch (error) {
      handleApiError(error, openSnackbar)
      return { data: { liveFeedRequestDtoList: [] } }
    }
  }

  const refreshData = () => {
    // Setting Lists null to refresh data
    setQuotes(null)
    setRequests(null)
  }

  const fetchQuotes = async (refresh) => {
    try {
      // await checkValidity()
      const { data } = await api.get(`/requests/livefeed/responsespagination?size=8`)
      updateQuotesCounter(data)
      setQuotes(data)
      return data
    } catch (error) {
      handleApiError(error, openSnackbar)
    }
  }

  const fetchMyRequests = async (refresh) => {
    try {
      // await checkValidity()
      if (refresh) refreshData()
      const { data } = await api.get(`requests`)
      updateMyRequestsCounter(data)
      setRequests(data.filter((item) => item.year && !item.archived))
      return data
    } catch (error) {
      handleApiError(error, openSnackbar)
    }
  }

  // Counter for Livefeed in Sidebar
  const livefeedUnreadCounter = async (refresh) => {
    try {
      // await checkValidity()
      if (refresh) refreshData()
      const { data } = await api.get(`requests/livefeedUnreadCount`)
      setCounter((prev) => ({ ...prev, livefeed: data.liveFeedUnreadCount }))
    } catch (error) {
      handleApiError(error, openSnackbar)
    }
  }

  // Counter for Outbounds Quotes in Sidebar
  const updateQuotesCounter = async (data) => {
    try {
      let count = 0
      data?.forEach((item) => {
        item.chatRoom.senderStatus.forEach((sItem) => {
          if (sItem.id == auth.data.id && sItem.flag != "read") {
            count += 1
          }
        })
      })
      setCounter((prev) => ({ ...prev, quotes: count }))
    } catch (error) {
      handleApiError(error, openSnackbar)
    }
  }

  // Counter for My Requests in Sidebar
  const updateMyRequestsCounter = async (data) => {
    try {
      let count = 0
      data?.forEach((item) => {
        count = count + item.responseCount
        item.infoNeededList?.forEach((nItem) => {
          if (nItem.requestRead === false) count += 1
        })
      })
      setCounter((prev) => ({ ...prev, requests: count }))
    } catch (error) {
      handleApiError(error, openSnackbar)
    }
  }

  const notificationRefresh = (fetches) => {
    setNotificationListener(() => fetches)
  }

  // Listening Firebase Push Notification to show Toast and update Sidebar Counter
  onMessageListener()
    .then((payload) => {
      console.log("Success Notification", payload)
      openSnackbar({ color: "info", message: payload.notification.title })
      // fetchLivefeed();
      fetchQuotes()
      fetchMyRequests()
    })
    .catch((err) => console.log("Failed Notification: ", err))

  // --- Api access for Salvage Yard Login ---
  const onConsumerPageLoad = async () => {
    let validity = await fetchValidLicense()
    fetchProfile()
    if ((auth.isLoginEntry == true && auth.data.subscriptionStatus == "Expired") || (auth.isLoginEntry == true && validity == false)) {
      navigate("/my-request")
    }
    fetchMakeModel()
    if (validity) {
      // fetchLivefeed();
      livefeedUnreadCounter()
      fetchQuotes()
      fetchMyRequests()
      const interval = setInterval(() => {
        if (!localStorage.getItem("authToken")) return
        // fetchLivefeed();
        livefeedUnreadCounter()
        fetchQuotes()
        fetchMyRequests()
      }, 35000)
      // Return the interval only if it's needed
      return () => clearInterval(interval)
    }
  }

  // --- Api access for Consumer Login  ---
  const onBusinessPageLoad = async () => {
    let validity = await fetchValidLicense()
    fetchProfile()
    fetchMakeModel()
    if (validity) {
      fetchMyRequests()
      const interval = setInterval(() => {
        if (!localStorage.getItem("authToken")) return
        fetchMyRequests()
      }, 35000)
      return () => clearInterval(interval)
    }
  }

  useEffect(() => {
    let clearPageInterval

    const loadPage = async () => {
      if (profiletype === "consumer") clearPageInterval = await onConsumerPageLoad()
      else clearPageInterval = await onBusinessPageLoad()
    }

    loadPage()

    return () => clearPageInterval()
  }, [pageReload])

  return (
    <>
      <Dialog dialog={logoutDialog} />
      <div className="sidebar-main">
        <div className="inner-logo-main">
          <img src={logoImg} className="img-fluid" />
          <a role="button" className="menu-toggle-btn" onClick={() => setMenuOpen(!menuOpen)}>
            <i className="fas fa-bars"></i>
          </a>
        </div>
        <div className={`nav-link-main ${menuOpen ? "d-block" : ""}`}>
          <ul>
            {!profiletype?.includes("business") && (
              <>
                <CustomLink to="/livefeed" counter={counter.livefeed} subscriptionStatus={auth.data.subscriptionStatus}>
                  <i className="fas fa-network-wired"></i> {lang.livefeed}
                </CustomLink>
                <CustomLink to="/my-quotes" counter={counter.quotes}>
                  <i className="fas fa-money-check"></i> {lang.my_quote}
                </CustomLink>
              </>
            )}
            <CustomLink to="/my-request" counter={counter.requests}>
              <i className="fas fa-comment-dots me-2 pe-1"></i> {lang.my_requests}
            </CustomLink>
            <CustomLink to="/archive">
              <i className="fas fa-archive me-3"></i>
              {lang.archive}
            </CustomLink>
            <CustomLink to="/settings">
              <i className="fas fa-cog me-2 pe-1"></i> {lang.settings}
            </CustomLink>
          </ul>
        </div>
      </div>
      <Outlet
        key={pageReload}
        context={{
          fetchProfile,
          fetchLivefeed,
          quotes,
          fetchQuotes,
          requests,
          fetchMyRequests,
          validLicense,
          checkValidity,
          auth,
          notificationRefresh,
          livefeedUnreadCounter,
        }}
      />
    </>
  )
}

const CustomLink = ({ children, to, counter, subscriptionStatus }) => {
  // let resolved = useResolvedPath(to)
  // let match = useMatch({ path: resolved.pathname, end: true })
  const location = useLocation()
  if (subscriptionStatus == "Expired")
    return (
      <li className={location.pathname.includes(to) ? "" : ""} style={{ position: "relative" }}>
        <Link to={to} replace={true} style={{ backgroundColor: "lightgrey" }}>
          {children}
        </Link>
        {counter ? (
          <span
            className="badge bg-color-ly"
            style={{
              position: "absolute",
              right: "-2em",
              fontSize: "1em",
              top: "10px",
              borderRadius: "50%",
            }}>
            {counter}
          </span>
        ) : null}
      </li>
    )
  return (
    <li className={location.pathname.includes(to) ? "active" : ""} style={{ position: "relative" }}>
      <Link to={to} replace={true}>
        {children}
      </Link>
      {counter ? (
        <span
          className="badge bg-color-ly"
          style={{
            position: "absolute",
            right: "-2em",
            fontSize: "1em",
            top: "10px",
            borderRadius: "50%",
          }}>
          {counter}
        </span>
      ) : null}
    </li>
  )
}
export default Layout
