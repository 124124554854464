import React, { useState, useEffect, useContext } from 'react';
import Slider from 'react-slider';
import '../../assets/css/DistanceSliderPage.css';
import api from "../../libs/apis/api";
import { handleApiError } from "../../libs/apis/handleApiError";
import { SnackbarContext } from "../../libs/contexts/SnackbarContext";
import {AuthContext} from "../../libs/contexts/AuthContext";  // Importing external CSS for clean styling

const DistanceSliderPage = () => {
    const { lang } = useContext(AuthContext)
    const [liveFeedDistance, setLiveFeedDistance] = useState(0); // Default value for the slider
    const { openSnackbar } = useContext(SnackbarContext);

    // Function to fetch data on page load
    const fetchData = async () => {
        try {
            const { data } = await api.get(`v1/salvage`);
            setLiveFeedDistance(data.liveFeedDistance || 0);  // Set the value from the API
        } catch (error) {
            handleApiError(error, openSnackbar);
        }
    };

    // Call fetchData on component mount
    useEffect(() => {
        fetchData();
    }, []); // Empty dependency array to ensure it's only called once

    // Function to format the slider label
    const formatLabel = (value) => {
        return value === 1000 ? `${lang.show_all}` : `${value} mi`;
    };

    // Handle slider change
    const handleSliderChange = (value) => {
        if (value === 1000) {
            setLiveFeedDistance(-1); // Set to -1 if slider is at "Show All"
        } else {
            setLiveFeedDistance(value);
        }
    };

    // Handle save action
    const handleSave = async () => {
        const formData = { liveFeedDistance };
        try {
            await api.post('v1/auth/signup/livefeed-distance', formData, {
                headers: {
                    'Content-Type': 'application/json',  // Ensure the request is sent as JSON
                }
            });
            openSnackbar('Distance updated successfully', 'success');
        } catch (error) {
            handleApiError(error, openSnackbar);
        }
    };

    return (
        <div className="page-container">
            <div className="slider-container">
                <h2>{lang.select_distance}</h2>
                <Slider
                    className="slider"
                    thumbClassName="thumb"
                    trackClassName="track"
                    value={liveFeedDistance === -1 ? 1000 : liveFeedDistance} // Set slider to "Show All" if -1
                    onChange={handleSliderChange}
                    min={0}
                    max={1000}
                    step={10}
                />
                <div className="distance-label">
                    {formatLabel(liveFeedDistance === -1 ? 1000 : liveFeedDistance)}
                </div>
                <button className="save-button" onClick={handleSave}>{lang.save}</button>
            </div>
        </div>
    );
};

export default DistanceSliderPage;
